import { useContext, useEffect, useState } from 'preact/compat';
import { PortfolioBrokerContext } from '../global-contexts';
export default function usePortfolioBundlePositions() {
    const portfolioBroker = useContext(PortfolioBrokerContext);
    const [state, setState] = useState(undefined);
    useEffect(()=>portfolioBroker === null || portfolioBroker === void 0 ? void 0 : portfolioBroker.onBundlePositions(setState), [
        portfolioBroker
    ]);
    return state;
}
